/* Remove all margins and set background to black */
body, html {
  margin: 0;
  padding: 0;
  background-color: black;
  color: #ffffff; /* White terminal text */
  font-family: 'Courier New', Courier, monospace; /* Classic terminal font */
  font-weight: bold;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Full-page app container */
.app {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align everything to the left */
  justify-content: flex-start;
  overflow: hidden;
}

/* Console styling */
.console {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Left-aligned text */
}

.logo {
  width: 150px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 20px;
}

.output-line {
  margin: 0;
  white-space: pre-wrap; /* Keep line breaks */
}

form {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.prompt {
  margin-right: 5px;
}

.input {
  background: none;
  border: none;
  color: #ffffff; /* White text */
  outline: none;
  font-size: 1rem;
  font-family: 'Courier New', Courier, monospace; /* Explicitly set the font */
  flex-grow: 1;
  padding: 0;
}